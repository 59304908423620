<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import StaffTab from "./components/staff.vue"
import DeptTab from "./components/department.vue"



/**
 * Advanced table component
 */
export default {
  page: {
    title: '基础配置',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,

    StaffTab,
    DeptTab

  },
  data() {
    return {

      title: this.$t('system.basic.sub_title'),
      items: [
        {
          text: this.$t('system.basic.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('system.basic.nav_second_item'),
          active: true,
        },
      ],
     
     
     
    };
  },
  computed: {
   


  },
  mounted() {


  },
  methods: {

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    
    <div class="row">
      <div class="col-12">
        <div class="card">
         
          <div class="card-body">
            <b-tabs class="mt-n5 pt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
              nav-wrapper-class="nav-tabs-custom">
              <b-tab :title="$t('system.basic.tab_staff')" active>
                <StaffTab />
              </b-tab>
              <b-tab :title="$t('system.basic.tab_dept')" >
                <DeptTab />
              </b-tab>
              <b-tab :title="$t('system.basic.tab_permission')" >
                <StaffTab />
              </b-tab>

            </b-tabs>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>


import request from '@/helpers/request';


class TKPSSessionApi {

    login_in = (data) => {
        return request({
            url: '/api/v1/user/login',
            method: 'post',
            data
        });	
    }

    login_out = () => {
        return request({
            url: '/api/v1/user/logout',
            method: 'get'
        });	
    }



}


class TKPSStaffApi {
    

    add_modify_staff = (data) => {
        return request({
            url: '/api/v1/user/add',
            method: 'post',
            data
        });	
    }


    list_staff   = (data) => {
        return request({
            url: '/api/v1/user/list',
            method: 'post',
            data
        });	
    }
}



let _api = null



export const useSessionApi = () => {
    if (!_api) {
        _api = new TKPSSessionApi();
    }
    return _api;
}


let _user_api = null

export const useStaffApi = () => {
    if (!_user_api) {
        _user_api = new TKPSStaffApi();
    }
    return _user_api;
}


<script>

import appConfig from "@/app.config";
import { required, } from "vuelidate/lib/validators";

import { useSystemApi } from "@/apis/system";



/**
 * Advanced table component
 */
export default {
  page: {
    title: '部门信息',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {


  },

  validations() {
    return {
      team_name: { required },
      team_code: { required }
    }
  },

  data() {
    return {

      title: this.$t('system.basic.dept.sub_title'),
      items: [
        {
          text: this.$t('system.basic.dept.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('system.basic.dept.nav_second_item'),
          active: true,
        },
      ],

      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [

        {
          key: "code",
          label: '部门编码',
          sortable: true,
        },

        {
          key: "dept",
          label: this.$t('system.basic.dept.table_fields.department'),
          sortable: true,
        },


        {
          key: "Pos",
          label: this.$t('system.basic.dept.table_fields.action'),
          sortable: false,
        },
      ],

      listData: [],

      team_name: '',
      team_code: '',
      update_flag: false,
      update_id: '',
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listData.length;
    },


  },
  mounted() {
    this.queryDept()

  },

  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    todoFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.todoTotalRows = filteredItems.length;
      this.todocurrentPage = 1;
    },

    queryDept() {
      useSystemApi().list_system_conf('DEPT').then(res => {
        if (res.err == 0) {
          this.listData = []
          res.data.map(e => {
            this.listData.push({
              dept: e.key_field,
              code: e.value_field,
              id: e.id
            })
          })
        }
      })
    },


    submitDept() {
      this.$v.$touch()

      if (this.$v.$invalid == false) {

        let data = {
          category: 'DEPT',
          key: this.team_code,
          value: this.team_name
        }

        if (this.update_flag == true) {
          data.id = this.update_id
          useSystemApi().modify_system_conf(data).then(res => {
            if (res.err == 0) {
              this.$bvModal.hide('dept_dialog')
              this.queryDept()
            } else {
              this.$alertify.error("编辑失败 " + res.err + ":" + res.msg);
            }
          })

        } else {
          useSystemApi().add_system_conf(data).then(res => {
            if (res.err == 0) {
              this.$bvModal.hide('dept_dialog')
              this.queryDept()
            } else {
              this.$alertify.error("失败 " + res.err + ":" + res.msg);
            }
          })

        }


      }
    },

    newDept() {
      this.update_flag = false;
      this.$bvModal.show('dept_dialog')
    },


    editDept(item) {
      this.update_flag = true;
      this.team_code = item.dept
      this.team_name = item.code
      this.update_id = item.id

      this.$bvModal.show('dept_dialog')
    },

    deleteDept(item) {
      useSystemApi().delete_system_conf(item.id).then(res => {
        console.log(res)
        if (res.err == 0) {
          this.queryDept()
        } else {
          this.$alertify.error("删除失败 " + res.err + ":" + res.msg);
        }
      })
    }

  },
};
</script>

<template>

  <div class="row">
    <b-modal id="dept_dialog" title="添加部门" size="lg" centered hide-footer>
      <div class="row mb-3">

        <div class="col-md-4">
          <label class="form-label">部门编码</label>
          <input type="text" class="form-control" v-model="team_code" maxlength="8"
            :class="{ 'is-invalid': $v.team_code.$error }">
          <div v-if="$v.team_code.$error" class="invalid-feedback">
            <span v-if="!$v.team_code.required">请输入部门编码</span>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label">部门名称</label>
          <input type="text" class="form-control" v-model="team_name" maxlength="30"
            :class="{ 'is-invalid': $v.team_name.$error }" />
          <div v-if="$v.team_name.$error" class="invalid-feedback">
            <span v-if="!$v.team_name.required">请输入部门名称</span>
          </div>
        </div>

      </div>
      <div class="row mb-3 ">

        <div class="col-md-8"></div>
        <div class="col-md-4  gap-2 justify-content-end d-flex align-items-right">
          <b-button variant="secondary" @click="$bvModal.hide('dept_dialog')">取消</b-button>
          <b-button variant="primary" @click="submitDept">确认</b-button>
        </div>

      </div>
    </b-modal>
    <div class="row">
      <div class="col-sm-6 col-md-3">

      </div>

      <div class="col-sm-12 col-md-3">
        <div class="row">

          <div class="col-md-6">
          </div>

        </div>


      </div>

      <div class="col-sm-6 col-md-3">
      </div>
      <!-- Search -->
      <div class="col-sm-6 col-md-3">
        <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
          <b-button variant="secondary" @click="newDept">{{ $t('system.basic.dept.btn.add_dept')
            }}</b-button>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table class="datatables" :items="listData" :fields="fields" responsive="sm" :per-page="perPage"
        :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered" style="min-height: 20dvh;">

        <template #cell(Pos)="data">
          <b-dropdown variant="white" toggle-class="p-0">
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
            </template>
            <li>
              <b-dropdown-item @click="editDept(data.item)"><i
                  class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{
                    $t('system.basic.dept.btn.edit_dept') }} </b-dropdown-item>
              <b-dropdown-item @click="deleteDept(data.item)"><i
                  class="mdi mdi-pencil font-size-16 text-success me-1"></i>删除
              </b-dropdown-item>
            </li>

          </b-dropdown>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>



</template>


import request from '@/helpers/request';


class SystemApi {

    add_system_conf = (data) => {
        return request({
            url: '/api/v1/system/add',
            method: 'post',
            data
        });	
    }


    list_system_conf =  (category, page) => {
        let data = {
            'category' : category,
            page : page
        }
        return request({
            url: '/api/v1/system/cache',
            method: 'post',
            data
        });	
    }

    modify_system_conf =  (data) => {
       
        return request({
            url: '/api/v1/system/modify',
            method: 'post',
            data
        });	
    }
 

    delete_system_conf =  (id) => {
       
        return request({
            url: '/api/v1/system/delete/'+id,
            method: 'post'
        });	
    }


    model_search =  (category, str) => {
        let data = {
            category : category,
            str : str
        }
       
        return request({
            url: '/api/v1/system/search',
            method: 'post',
            data
        });	
    }
 
    
}



let _api = null

export const useSystemApi = () => {
    if (!_api) {
        _api = new SystemApi();
    }
    return _api;
}

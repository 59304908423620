<script>

import appConfig from "@/app.config";


import { required, } from "vuelidate/lib/validators";


import {useStaffApi} from '@/apis/session'

/**
 * Advanced table component
 */
export default {
  page: {
    title: '人员信息',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {


  },

  validations() {
    return {
      staff: {
        account: { required },
        user_name: { required },
        password: { required },

      }

    }
  },

  data() {
    return {

      title: this.$t('system.basic.staff.sub_title'),
      items: [
        {
          text: this.$t('system.basic.staff.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('system.basic.staff.nav_second_item'),
          active: true,
        },
      ],
      issue_url: '',
      totalRows: 1,
      currentPage: 1,
      perPage: 10,


      fields: [

        {
          key: "user_name",
          label: this.$t('system.basic.staff.table_fields.name'),
          sortable: true,
        },

        {
          key: "dept_name",
          label: this.$t('system.basic.staff.table_fields.department'),
          sortable: true,
        },

        {
          key: "Account",
          label: this.$t('system.basic.staff.table_fields.account'),
          sortable: true,
        },

        {
          key: "status",
          label: this.$t('system.basic.staff.table_fields.status'),
          sortable: true,
        },


        {
          key: "Pos",
          label: this.$t('system.basic.staff.table_fields.action'),
          sortable: false,
        },
      ],

      staffList: [],
      staff: {
        account: '',
        user_name: '',
        user_dept: '',
        password: ''
      },
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },


  },
  mounted() {

    this.query_staff()

    

  },
  methods: {
   


    submitStaff() {
      console.log('---- add ', this.currentPage)
      if (!this.staff.user_id) {
        useStaffApi().add_modify_staff(this.staff).then(res => {
          if (res.err == 0) {
            this.$alertify.error("操作成功");
            this.$bvModal.hide('staff_dialog')
            this.query_staff()
          } else {
            this.$alertify.error("创建失败 " + res.err + ":" + res.msg);
          }
         
        })
      }
    },

    edit_staff(staff) {
      this.staff = staff
    },

    query_staff() {
     
      useStaffApi().list_staff({page : {page_no : this.currentPage, page_size : this.perPage}}).then(res => {
        
          if (res.err == 0) {
            this.staffList = []
            res.data.map(e => {
              this.staffList.push(e)
            })

            this.totalRows = res.page.total_count
            
          }
          
        })
    },


    get_account(staff) {
      if (staff.accounts && staff.accounts.length > 0) {
        return staff.accounts[0].login_account
      } else {
        return 'N/A'
      }
    },

    pageChange(page) {
      this.currentPage = page
      this.query_staff()
    }

  },
};
</script>

<template>


  <div class="row">
    <b-modal id="staff_dialog" title="添加人员" size="lg" centered hide-footer :static=true>
      <div class="row mb-3">

        <div class="col-md-4">
          <label class="form-label">人员姓名</label>
          <input type="text" class="form-control" v-model="staff.user_name" maxlength="30"
            :class="{ 'is-invalid': $v.staff.user_name.$error }">
          <div v-if="$v.staff.user_name.$error" class="invalid-feedback">
            <span v-if="!$v.staff.user_name.required">请输入人员姓名</span>
          </div>
        </div>

        <div class="col-md-4">
          <label class="form-label">登录账号</label>
          <input type="text" class="form-control" v-model="staff.account" maxlength="30"
            :class="{ 'is-invalid': $v.staff.account.$error }">
          <div v-if="$v.staff.account.$error" class="invalid-feedback">
            <span v-if="!$v.staff.account.required">请输入部门编码</span>
          </div>
        </div>
        <div class="col-md-4">
          <label class="form-label">部门名称</label>
          <input type="text" class="form-control" v-model="staff.user_dept"  />
        </div>

      </div>
      <div class="row mb-3 ">

        <div class="col-md-8"></div>
        <div class="col-md-4  gap-2 justify-content-end d-flex align-items-right">
          <b-button variant="secondary" @click="$bvModal.hide('staff_dialog')">取消</b-button>
          <b-button variant="primary" @click="submitStaff">确认</b-button>
        </div>
      </div>
    </b-modal>

    <div class="row">
      <div class="col-sm-6 col-md-3">

      </div>

      <div class="col-sm-12 col-md-3">
        <div class="row">

          <div class="col-md-6">
          </div>

        </div>


      </div>

      <div class="col-sm-6 col-md-3">
      </div>
      <!-- Search -->
      <div class="col-sm-6 col-md-3">
        <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
          <b-button variant="secondary" v-b-modal.staff_dialog>{{ $t('system.basic.staff.btn.add_staff')
            }}</b-button>
        </div>
      </div>
      <!-- End search -->
    </div>
    <!-- Table -->
    <div class="table-responsive mb-0">
      <b-table class="datatables" :items="staffList" :fields="fields" responsive="sm" :per-page="perPage" style="min-height: 20dvh;">

        <template #cell(Account)="data">
          {{get_account(data.item) }}
        </template>
        <template #cell(Pos)="data">
          <b-dropdown variant="white" toggle-class="p-0">
            <template v-slot:button-content>
              <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
            </template>
            <li>
              <b-dropdown-item @click="edit_staff(data.item)"><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{
                $t('system.basic.staff.btn.edit_staff') }} </b-dropdown-item>
            </li>
            <li>
              <b-dropdown-item><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{
                $t('system.basic.staff.btn.account_enable') }} </b-dropdown-item>
            </li>
            <li>
              <b-dropdown-item><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{
                $t('system.basic.staff.btn.account_disable') }} </b-dropdown-item>
            </li>

          </b-dropdown>
        </template>
      </b-table>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" @change="pageChange"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>



</template>
